<template>
  <div class="blue-skin">
    <div id="main-wrapper">
      <app-header />

      <div class="clearfix"></div>

      <div class="page-title">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 col-md-12">
              <h2 class="ipt-title">Frequently Asked Questions</h2>
              <span class="ipn-subtitle">Get quick answers</span>
            </div>
          </div>
        </div>
      </div>

      <section class="gray pt-4">
        <div class="container" v-if="! loading">
          <div class="card mb-3" v-for="(item, i) in faqs" :key="`item-${i}`">
            <div class="card-header bg-light">
              <h4 class="card-title mb-0">{{ item.title }}</h4>
            </div>
            <div class="card-body pt-2">{{ item.content }}</div>
          </div>
        </div>
        <div class="container" v-if="loading">
          <loading />
        </div>
      </section>

      <call-to-action />

      <app-footer />
    </div>
  </div>
</template>

<script>
import AppHeader from "./components/AppHeader.vue";
import AppFooter from "./components/AppFooter.vue";

export default {
  components: {
    AppHeader,
    AppFooter,
  },

  mounted() {
    this.fetch()
  },

  data() {
    return {
      faqs: [],
      loading: true
    }
  },

  methods: {
    fetch() {
      this.loading = true

      this.$axios.get('/api/v1/content/faq').then(response => {
        this.faqs = response.data.content
        this.loading = false
      })
    }
  }
};
</script>
